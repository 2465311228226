const initialState = {
    success: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case 'LOGIN_REQUEST': {
        return initialState;
      }
      case 'LOGIN_SUCCESS': {
        return {...state, success: true};
      }
      case 'LOGIN_FAILURE': {
        return {...state, error: true};
      }
      case 'LOGOUT' : {
        return Object.assign({}, state);
      }
      default:
        return state;
    }
  }
  