import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import { Route, Switch, BrowserRouter as Router, withRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

import CookieAlert from "./components/Shared/CookieAlert";
import { getCookie } from "./utils/cookie";

// Notification
import NotificationContainer from "./components/Shared/Notification";

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

// Include Routes
import routes from "./routes";

function withLayout(WrappedComponent, hasDarkTopBar) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {

    return (
      <React.Fragment>
        <Provider store={store}>
          <Router>
            <Suspense fallback={this.Loader()}>
              <Switch>
                {routes.map((route, idx) =>
                  route.isWithoutLayout ? (
                    <Route
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                      key={idx}
                    />
                  ) : (
                    <Route
                      path={route.path}
                      exact
                      component={withLayout(route.component, route.isTopbarDark)}
                      key={idx}
                    />
                  )
                )}
              </Switch>
            </Suspense>

          </Router>

          {!getCookie('cookie-policy') &&
            <CookieAlert />
          }

          <NotificationContainer props />

        </Provider>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
