import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import { setCookie, getCookie, deleteAllCookies } from "../../utils/cookie";

import CookiePolicyTr from '../../assets/documents/CookiePolicyTR.pdf'

class CookieAlert extends PureComponent {

  state = {
    cookie: getCookie('cookie-policy')
  };

  acceptCookie = async () => {
    const { i18n } = this.props;

    await deleteAllCookies();
    await setCookie('cookie-policy', 'true', 365);
    await setCookie('i18nextLng', i18n.language, 365);
    this.setState({ cookie: getCookie('cookie-policy') });
  }


  rejectCookie = async () => {
    await deleteAllCookies();
    await setCookie('cookie-policy', 'false', 365);
    this.setState({ cookie: getCookie('cookie-policy') });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <React.Fragment>
        <div class={`"alert text-center cookiealert ${!this.state.cookie ? "show" : ""} `} role="alert">
          <div className="d-flex flex-column align-items-center">
            {i18n.language === 'tr' ?
              <p>Bu web sitesi çerezleri bilgisayarınızda saklar. Bu çerezler, hem bu web sitesinde hem de diğer medya aracılığıyla web sitesi deneyiminizi iyileştirmek ve size daha kişiselleştirilmiş hizmetler ve teklifler sunmak için kullanılır. Bizim  kullandığımız çerezler hakkında daha fazla bilgi edinmek için <b><a href={CookiePolicyTr} target="_blank">Çerez Politikamıza</a></b> bakınız.</p>
              :
              <p>This website stores cookies on your computer. These cookies are used to improve your website experience and provide you with more personalized services and offers, both on this website and through other media. To learn more about the cookies we use, see our <b><a href={CookiePolicyTr} target="_blank">Cookie Policy</a></b>.</p>

            }
            <div className="pb-2">
              <button type="button" className=" btn-outline" onClick={() => this.acceptCookie()}>
                {i18n.language === 'tr' ?
                  <span>Onaylıyorum</span>
                  :
                  <span>I agree</span>

                }
              </button>
              {/* 
              <button type="button" className="ms-3 btn-outline" onClick={() => this.rejectCookie()}>
                Reject All
              </button>
            */}
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation('common')(CookieAlert);
