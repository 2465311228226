import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { reducer as notifications } from "react-notification-system-redux";

import {

  signupReducer,
  loginReducer,
  postRequest
} from "./redux/reducers/index";


const reducer = combineReducers({

  // Notification
  notifications: notifications,

  // Auth
  signup: signupReducer,
  login: loginReducer,

  // Live Support
  liveSupportSave: postRequest('public/live/support/save')
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
