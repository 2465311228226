const initialState = {
  success: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SIGNUP_REQUEST': {
      return initialState;
    }
    case 'SIGNUP_SUCCESS': {
      return {...state, success: true};
    }
    case 'SIGNUP_FAILURE': {
      return {...state, error: true};
    }
    default:
      return state;
  }
}
