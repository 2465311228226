import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import resources from './resources';

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: false,
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'navigator'],
      lookupCookie: 'i18nextLng',
    },
    caches: ['cookie'],

    resources,
    ns: ['common', 'message'],
    defaultNS: 'common',
    whitelist: ['tr', 'en'],

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;