import React from "react";

//Router Pages
const SignUpRouter = React.lazy(() => import("./pages/Pages/AuthPages/PageSignup/index"));
const LoginRouter = React.lazy(() => import("./pages/Pages/AuthPages/LoginRouter"));

//Auth Pages
const PageSignup = React.lazy(() => import("./pages/Pages/AuthPages/PageSignup/index"));
const PageLogin = React.lazy(() => import("./pages/Pages/AuthPages/PageLogin/index"));
const PageRecoveryPassword = React.lazy(() => import("./pages/Pages/AuthPages/PageRecoryPassword/index"));

const LoginPreperties = React.lazy(() => import("./pages/Pages/AuthPages/LoginProperties/index"));
//Home
const Home = React.lazy(() => import("./pages/Home"));

//Researchers
const Researchers = React.lazy(() => import('./pages/Researchers'));

//Researchers
const Companies = React.lazy(() => import('./pages/Companies'));

//About Us
const AboutUs = React.lazy(() => import('./pages/AboutUs'));

//Contact Us
const ContactUs = React.lazy(() => import('./pages/ContactUs'));

//Special
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));


const routes = [

  //Auth Pages
  { path: "/signup", component: SignUpRouter, isWithoutLayout: true },
  { path: "/login", component: LoginRouter, isWithoutLayout: true },

  {path: "/login-option", component: LoginPreperties, isWithoutLayout: true},
  //Router Pages
  { path: "/signup", component: SignUpRouter, isWithoutLayout: true },
  { path: "/login", component: LoginRouter, isWithoutLayout: true },

  //Auth Pages
  { path: "/auth-signup", component: PageSignup, isWithoutLayout: true },
  { path: "/auth-login", component: PageLogin, isWithoutLayout: true },
  { path: "/auth-recovery-password", component: PageRecoveryPassword, isWithoutLayout: true },
 
  //Home
  { path: "/", component: Home },
  { path: "/researchers", component: Researchers },
  { path: "/organizations", component: Companies },
  { path: "/about-us", component: AboutUs },
  { path: "/contact-us", component: ContactUs },
  { component: PageError, isWithoutLayout: true, exact: false },

];

export default routes;
